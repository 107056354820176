<template>
  <div>
    <div class="ticketTextHeading">Graph</div>
    <br /><br />
    <v-btn
      color="#38227A"
      dark
      class="px-11 text-capitalize"
      style="border-radius: 10px; margin: 5px; width: 130px"
      height="45px"
      @click="toggleGraphModal({ show: true, type: 'add' })"
    >
      <span>Offline Data</span>
    </v-btn>
    <v-btn 
      @click="toggleView" 
      color="#38227A" 
      dark 
      class="px-11 text-capitalize"
      style="border-radius: 10px; margin: 5px; width: 130px"
      height="45px">
      <span>{{ showTable ? 'Show Graph' : 'Show Table' }}</span>
    </v-btn>

    <div v-show="!showTable">
      <canvas ref="lineChart" style="height: 400px"></canvas>
    </div>
    
    <graph-modal></graph-modal>
    
    <div v-if="showTable" class="data-table">
      <v-simple-table class="elevation-8 custom-table" style="margin-top: 2%">
        <template v-slot:default>
          <thead>
            <tr class="table-header">
              <th style="width: 200px">Details</th>
              <th class="textHeader text-center" v-for="(item, i) in labels" :key="i">
                {{ item }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, i) in tableresponseData.rows" :key="i">
              <td class="date-cell">
                <div class="date-info"><span class="date-label">Assembly Date:</span> {{ item.assembly_date }}</div>
                <div class="date-info"><span class="date-label">Game Date:</span> {{ item.game_date }}</div>
              </td>
              <td class="text-center value-cell" v-for="(rowval, i) in item.values" :key="i">
                {{ rowval }}
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </div>
  </div>
</template>

<script>
import Chart from "chart.js";
import { mapActions } from "vuex";
import { GAME_GRAPH_API, GAME_TABLE_API } from "@/constants/APIUrls";
import Axios from "@/api/BaseAxios";

export default {
  components: {
    GraphModal: () => import("../GraphModal/GraphModal"),
  },
  data() {
    return {
      showTable: true, // Show table by default
      tableresponseData: {},
      labels: [],
      chartInstance: null,
    };
  },
  mounted() {
    this.getTblGraphData();
  },
  methods: {
    ...mapActions({ toggleGraphModal: "gameManagement/toggleGraphModal" }),
    
    getTblGraphData() {
      const successHandler = (res) => {
        this.tableresponseData = res.data.table_data;
        this.labels = this.tableresponseData.columns.map((label) =>
          label.replace("days_", "Days ").replace("game_day", "Game Day").replace("final", "Final")
        );
      };

      const failureHandler = (res) => {
        console.log(res);
      };
      
      Axios.request_GET(
        GAME_TABLE_API,
        { game_id: this.$route.query.game_id },
        {},
        successHandler,
        failureHandler,
        false,
        true
      );
    },
    
    getGraphData() {
      const successHandler = (res) => {
        const labels = res.data.labels;
        const graphData = res.data.graph_data.map(item => ({ label: item.game_date, data: item.data }));
        this.renderLineChart(labels, graphData);
      };
      
      Axios.request_GET(
        GAME_GRAPH_API,
        { game_id: this.$route.query.game_id },
        {},
        successHandler,
        console.log,
        false,
        true
      );
    },
    
    toggleView() {
      this.showTable = !this.showTable;
      if (!this.showTable) {
        this.getGraphData();
      }
    },
    
    renderLineChart(labels, graphData) {
      if (this.chartInstance) {
        this.chartInstance.destroy();
      }
      
      const ctx = this.$refs.lineChart.getContext("2d");
      this.chartInstance = new Chart(ctx, {
        type: "line",
        data: {
          labels: labels,
          datasets: graphData.map(data => ({
            label: data.label,
            data: data.data,
            borderColor: getRandomColor(),
            borderWidth: 2,
            fill: false,
          })),
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          scales: {
            xAxes: [{
              scaleLabel: { display: true, labelString: "Days", fontSize: 14, fontStyle: "bold" },
              ticks: { maxRotation: 0, minRotation: 0 },
            }],
            yAxes: [{
              ticks: { beginAtZero: true },
            }],
          },
        },
      });
    },
  },
};

function getRandomColor() {
  return `#${Math.floor(Math.random() * 16777215).toString(16)}`;
}
</script>

<style scoped>
.ticketTextHeading {
  font-family: Roboto Slab;
  font-weight: 700;
  font-size: 32px;
  color: #2c1963;
}
.custom-table { width: 100%; border-collapse: collapse; }
.table-header { background: #e7e5ed; font-weight: bold; }
.date-cell { padding: 10px; background-color: #f9f9f9; font-size: 14px; }
.date-info { margin-bottom: 5px; }
.date-label { font-weight: bold; font-size: 13px; color: #6c757d; }
.date-value { display: inline-block; margin-left: 5px; color: #333; }
.value-cell { padding: 10px; font-size: 16px; background-color: #ffffff; }
.elevation-8 { box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1); }
.textHeader { padding: 10px; font-size: 16px; color: #333; }
</style>
